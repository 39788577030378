import React, { useEffect } from "react";
import { gsap } from "gsap";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
// import debounce from 'jquery-debounce-throttle';
import "./styles.css";
import boardingPassSvg from "./images/boardingpass-mobile.svg";
import stamp2 from "./images/stamp2.png";
import stamp from "./images/stamp-suriname.png";
import SVG from "react-inlinesvg";

window.jQuery = window.$ = $;

function App() {
  // const landscapeRatio = 2.1;
  const portraitRatio = 2.35;

  const animateCard = () => {
    $(window).scrollTop(0);
    CreateCard();
  };

  const toggleCard = () => {
    $("#portrait").show();
    // if (isPortrait()) {
    //   $("#portrait").show();
    //   $("#landscape").hide();
    // } else {
    //   $("#portrait").hide();
    //   $("#landscape").show();
    // }
    let envelopeWidth = $(".container-fluid .row").width();
    // let envelopeWidth = $('#envelopePortrait').width();
    // let envelopeHeight = isPortrait()
    //   ? portraitRatio * envelopeWidth
    //   : envelopeWidth / landscapeRatio; // "55%";
    let envelopeHeight = portraitRatio * envelopeWidth;
    $("#envelope").css("height", envelopeHeight + "px");
  };

  function CreateCard() {
    toggleCard();

    let envelopeWidth = $(".container-fluid .row > div").width();
    console.log(envelopeWidth);
    let envelopeHeight = portraitRatio * envelopeWidth;// '100%'; //portraitRatio * envelopeWidth;
    // let envelopeHeight = isPortrait()
    //   ? portraitRatio * envelopeWidth
    //   : envelopeWidth / landscapeRatio; // "55%";

    let letterMoveUpHeight = "-100%";

    if ($(window).width() <= 1024) {
      // envelopeHeight = "55%";
      if (isPortrait() && $(window).height() >= 991) {
        // envelopeHeight = "150%";
      }
    }

    if ($(window).width() < 768) {
      // envelopeHeight = "70%";
      letterMoveUpHeight = "-155%";
    }

    let timeline = gsap.timeline();

    // Grow envelope on page load
    timeline.to("#envelope", {
      duration: 0.75,
      display: "block",
      top: 0,
      left: 0,
      width: "100%",
      height: envelopeHeight,
    });

    // Show stamps and recipient
    timeline.to("#stamp, #stamp2, #invitationRecipient", {
      display: "block",
    });

    // Flip envelope
    timeline.to("#envelopeBack", { duration: 1, rotationY: 0 }, 5);
    timeline.to("#envelopeFront", { duration: 1, rotationY: -180 }, 5);

    // Open envelope
    timeline.to("#upperFold", {
      duration: 1,
      transformOrigin: "top center",
      rotationX: 180,
    });
    timeline.to("#upperFoldBack", { zIndex: 2 }, "+=0.35");
    timeline.to("#upperFold", { zIndex: 0 });

    // Pull out invitation
    timeline.to("#letter", { y: letterMoveUpHeight });
    timeline.to("#letter", { y: -letterMoveUpHeight, zIndex: 4 }, "+=0.5");

    // Rotate envelope and contra-rotate letter
    timeline.to("#envelope", { duration: 0.5, rotation: 5 });
    timeline.to(
      "#letter",
      { duration: 0.5, transformOrigin: "center", rotate: -5 },
      "-=0.5"
    );

    timeline.fromTo(
      "#bg, #Uitnodiging",
      {opacity: 0},
      {duration: 1.5, opacity: 1},
      "+=0.5"
    );

    timeline.fromTo(
      "#Intro_1",
      {opacity: 0},
      {duration: 1.5, opacity: 1},
      "+=0.5"
    );

    timeline.fromTo(
      "#Intro_2",
      {opacity: 0},
      {duration: 1.5, stagger: -0.25, opacity: 1},
      "+=2.0"
    );

    timeline.fromTo(
      "#Intro_3",
      {opacity: 0},
      {duration: 1.5, stagger: -0.25, opacity: 1},
      "+=2.5"
    );

    timeline.fromTo(
      "#Akash",
      { opacity: 0, y: -100, scale: 0.5, transformOrigin: "center top" },
      { duration: 2.5, opacity: 1, y: 0, scale: 1 },
      "+=2.5"
    );

    timeline.fromTo(
      "#and",
      { opacity: 0, y: -100 },
      { opacity: 1, y: 0 },
      "+=1.5"
    );

    timeline.fromTo(
      "#Jen",
      { opacity: 0, y: -100, scale: 0.5, transformOrigin: "center top"  },
      { duration: 2.5, opacity: 1, y: 0, scale: 1 },
    );

    timeline.fromTo(
      "#H_05, #H_Dec, #H_2021",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0 },
      "+=1.75"
    );

    timeline.fromTo(
      "#Huwelijk, #Huwelijk_Text",
      { opacity: 0, x: 50 },
      { opacity: 1, x: 0 }
    );

    timeline.fromTo(
      "#R_08, #R_Dec, #R_2021",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0 },
      "+=2.75"
    );

    timeline.fromTo(
      "#Receptie, #Receptie_Text",
      { opacity: 0, x: 50 },
      { opacity: 1, x: 0 },
    );

    timeline.fromTo(
      "#Outro_1",
      {opacity: 0},
      {duration: 1.5, opacity: 1},
      "+=2.0"
    );

    timeline.fromTo(
      "#Outro_2",
      {opacity: 0},
      {duration: 1.5, opacity: 1},
      "+=2.0"
    );

    timeline.fromTo(
      "#Outro_3",
      {opacity: 0},
      {duration: 1.5, opacity: 1},
      "+=2.0"
    );

    $(window).scrollTop(0);
  }

  function isPortrait() {
    return true;
    // if (window.innerHeight > window.innerWidth) {
    //   return true;
    // }
    // return false;
  }

  useEffect(() => {
    $(window).scrollTop(0);

    $(window).on("resize orientationchange", function () {
      // debounceToggleCard();
      toggleCard();
    });
  });

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-12 col-sm-offset-3 col-sm-6 col-md-offset-4 col-md-4 col-lg-offset-4 col-lg-4">
          <div id="envelope">
            <div id="envelopeFront" className="envelopeFaces">
              <img id="stamp" src={stamp} alt="Suriname Postzegel SRD6" />
              <img id="stamp2" src={stamp2} alt="Priority Stamp" />
              <h1 id="invitationRecipient">Uitnodiging</h1>
            </div>
            <div id="envelopeBack" className="envelopeFaces">
              {/* <!-- The div below is the flap of the envelope --> */}
              <div id="upperFold">
                <div id="upperFoldFront"></div>
                <div id="upperFoldBack"></div>
              </div>
              <div id="lowerFold"></div>
              <div id="leftFold" className="folds"></div>
              <div id="rightFold" className="folds"></div>
              <div id="letter">
                <div className="row">
                  <div id="envelopePortrait" className="col-xs-12">
                    <SVG
                      src={boardingPassSvg}
                      className="img-responsive svg"
                      id="portrait"
                      onLoad={() => animateCard()}
                      alt="Uitnodiging voor het huwelijk van Akash en Jennefer "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
